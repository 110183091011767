import React from "react"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import cardStyles from "./card.module.scss"

export default function MediaCard(props) {
  return (
    <Card className={cardStyles.card}>
      <CardActionArea>
        <CardMedia
          className={cardStyles.media}
          image={props.img}
          title={props.title}
        />
        <CardContent className={cardStyles.content}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={cardStyles.cardTitle}
          >
            {props.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={cardStyles.cardText}
          >
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={cardStyles.cardActions}>
        <AniLink
          to={`blog/${props.slug}`}
          fade
          title={`Link a ${props.title}`}
          style={{ textDecoration: "none" }}
        >
          <Button
            size="small"
            color="primary"
            style={{
              fontFamily: "Roboto",
              textTransform: "none",
              fontSize: "1em",
            }}
          >
            Saber más
          </Button>
        </AniLink>
      </CardActions>
    </Card>
  )
}
