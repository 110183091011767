import React from "react"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
// import blog from '../images/blog.jpg'
import blogStyles from "./blog.module.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Card from "../components/card"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            id
            title
            slug
            publishedDate(formatString: "DD/MMMM/YYYY", locale: "es")
            postImage {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div className={blogStyles.container}>
        <SEO
          title="Blog"
          description="Información de valor y actualizada para padres conscientes"
          url="/blog"
        />
        <h1 className={blogStyles.title}>Blog</h1>
        <div className={blogStyles.postsContainer}>
          {data.allContentfulBlogPost.edges.map(edge => {
            return (
              <AniLink
                className={blogStyles.anilink}
                title={edge.node.title}
                fade
                to={`/blog/${edge.node.slug}`}
              >
                <Card
                  img={edge.node.postImage.file.url}
                  title={
                    edge.node.title.length > 100
                      ? edge.node.title.slice(0, 100) + "..."
                      : edge.node.title
                  }
                  description={edge.node.publishedDate}
                  slug={edge.node.slug}
                />
              </AniLink>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BlogPage
